<template>
  <div class="main-raised-page-wrapper">

    <div id="main-panel" class="main main-raised">
      <div class="section">
        <div class="container">
            <h1 class="title">
              Terms and Conditions
            </h1>

          <p>Access to and use of this site ('www.firecrest.co.nz') is provided by the Firecrest Systems subject to the following terms.                <strong>If you do not accept the terms and conditions listed, please do not use the Site.</strong></p>

          <ol>
              <li><a href="#A1" @click.prevent="smoothScroll('A1')">Eligibility</a></li>
              <li><a href="#A2" @click.prevent="smoothScroll('A2')">License</a></li>
              <li><a href="#A3" @click.prevent="smoothScroll('A3')">User submissions</a></li>
              <li><a href="#A4" @click.prevent="smoothScroll('A4')">General prohibition</a></li>
              <li><a href="#A5" @click.prevent="smoothScroll('A5')">Disclaimer</a></li>
              <li><a href="#A6" @click.prevent="smoothScroll('A6')">Termination</a></li>
              <li><a href="#A7" @click.prevent="smoothScroll('A7')">Contact Information</a></li>
          </ol>

          <h2 id="A1">1. Eligibility</h2>

          <p>The Site and any related services are available to you, provided that you can form legally binding agreements under applicable law. The Site is not available to minors. If you are a minor, please do not use the Site.</p>

          <h2 id="A2">2. License</h2>

          <p>Firecrest Systems grants you a limited, non-exclusive, non-transferable, non-assignable, revocable license to view and temporarily download a copy of the materials displayed on the Site solely for your personal and non-commercial use for yourself or within your organization.                All materials displayed or made available on the Site, including, but not limited to, graphics, documents, text, images, sound, video, audio, artwork, software, and HTML code (collectively, the "Material") are exclusive property of Firecrest Systems or its content suppliers. T               he Materials are protected by international copyright laws and any other applicable intellectual property rules, regulations, and laws.                Except as expressly permitted herein, you shall not (i) use, copy, modify, display, delete, distribute, download, store, reproduce, transmit, publish, sell, re-sell, adapt, reverse engineer, or create derivative works of the Material, or (ii) use the Material on other web sites or any media, e.g., networking environment, without Firecrest Systems' prior written consent.</p>
          <p>All trademarks, service marks, and logos displayed on the Site are exclusive property of Firecrest Systems and their respective owners. You shall not use the Marks in any manner without Firecrest Systems' and their respective owners' prior written consent.</p>

          <h2 id="A3">3. User submissions</h2>

          <p>You acknowledge and agree that Firecrest Systems' may retain copies of your Submitted Materials and disclose your Submitted Materials to a third party if Firecrest Systems' believes that it is necessary to: (i) protect the integrity of the Site; (ii) protect rights of Firecrest Systems'; (iii) comply with any court order; (iv) comply with any legal proceedings; (v) assert Firecrest Systems' claim under this Notice; and (vi) satisfy any claims regarding violations of third party's rights.</p>

          <h2 id="A4">4. General prohibition</h2>

          <p>In connection with your Use, you agree NOT to:</p>
          <ul>
              <li>infringe any intellectual property and privacy rights, including, but not limited to, patent, copyright, trademark, or trade secrets, of any third party;</li>
              <li>upload, post, transmit, or store any material that:
              <ul>
                  <li>is unlawful, offensive, defamatory, fraudulent, deceptive, misleading, harmful, threatening, harassing, obscene, or objectionable;</li>
                  <li>breaches any of your contractual or confidentiality obligations;</li>
                  <li>disrupts or interferes with the normal operations of the Site, such as posting or transmitting viruses, continuous posting of repetitive materials, or posting abnormally large load; or</li>
                  <li>are not permitted by Firecrest Systems, including, but not limited to, any unauthorized advertising materials, unsolicited promotional materials, "junk mail," "spam mail," "chain letters," pyramid schemes, franchises, distributorship, club membership, sales arrangement, or otherwise unacceptable materials;</li>
              </ul>
              </li>
              <li>violate other's privacy rights or personal rights by abusing the Materials, including, but not limited to, harassing or "stalking" another person, sending unsolicited e-mails, and collecting other's personal information;</li>
              <li>breach or attempt to breach any security measures of the Site;</li>
              <li>use any device, process, or mechanism to monitor, retrieve, search, or access, e.g., spider or robot, the Site or any Material without Firecrest Systems' prior written consent;</li>
              <li>access or attempt to access any account or login of any third party listed on the Site;</li>
              <li>copy, modify, reproduce, delete, distribute, download, store, transmit, sell, re-sell, publish, reverse engineer, or create derivative works of any Materials, except for materials that have been submitted and owned by you;</li>
              <li>post or submit any inaccurate, false, or incomplete information, such as your resume, biographical data, or employment information;</li>
              <li>impersonate any person or entity;</li>
              <li>forge any header information in any electronic posting or mail; or</li>
              <li>misrepresent yourself, your affiliation with any third party, or your entity.</li>
          </ul>

          <h2 id="A5">5. Disclaimer</h2>

          <p>You Acknowledge and accept that:                (A) You assume all risk related to or resulting from your usage, viewing, or access of the site. The site is provided on an "AS IS" and an "as available" basis.                (B) Firecrest Systems expressly disclaims all warranties of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, non-infringement, or warranties arising from course of performance, course of dealing or usage.                 (C) Firecrest Systems expressly disclaims all warranties that                 (I) The site and its materials will be error-free or virus-free;                 (II) The site will be uniterrupted and secure;                 (III) The site will be uniterrupted and all available at all times;                 (IV) The site will meet your requirements; and                 (V) The reliability, accuracy, completeness, validity, or truthfulness of any submitted materials.</p>

          <h2 id="A6">6. Termination</h2>

          <p>Firecrest Systems has the right, in its sole discretion, to terminate any services of the Site and remove any Materials from the Site. Firecrest Systems may also terminate your access to any part or all of the services provided by Firecrest Systems on the Site at any time, with or without cause or notice, for any reasons. If you want to terminate your account, you may only cease your Use of the Site. Firecrest Systems shall not be responsible for maintaining or returning your Submitted Materials, your account, or your logon and password. You should always maintain a copy of your Submitted Materials.</p>

          <h2 id="A7">7. Contact Information</h2>
          
          <p>If you have any questions about our website terms and conditions, please feel free to contact us by email <a href="mailto:support@firecrest.co.nz">support@firecrest.co.nz</a></p>
        </div>
      </div>
    </div>
  </div>
  </template>
 
 <script>
 export default {
   name: "Terms",
 methods: {
   smoothScroll(targetId) {
     const targetElement = document.getElementById(targetId);
     if (targetElement) {
       window.scrollTo({
         top: targetElement.offsetTop,
         behavior: 'smooth',
       });
     }
   },
 },
 };
 </script>
 
 <style scoped>
 
 </style>

