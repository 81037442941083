<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              About Us
            </h1>
            <h4>
              Firecrest Systems is your business support team.
            </h4>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-about-description text-center pb-0">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item">
              <h5 class="description">
                We partner with your business to achieve your goals and identify opportunities to get results for your business.
                We take our role as your I.T. guides seriously and work hard to understand your needs and come up with great strategies to meet your ongoing requirements.
              </h5>
              <fc-values/>
            </div>
          </div>
          <div class="md-layout">
            <div
              class="md-layout-item"
            >
              <h2 class="title">
                We build awesome products and solutions for a range of groups
              </h2>
              <h5 class="description">
                We have worked with healthcare professionals, radio and media providers, non-profit organisations, small, medium, and large businesses. If you have a need of I.T. or a project we can help.
              </h5>
            </div>
          </div>
          <div class="md-layout">
            <div
              class="md-layout-item"
            >
              <h3 class="title">
                Skills
              </h3>
              <h5 class="description">
                Business Analysis, Consulting, CRM, Desktop Support, Graphic Design, Hosting, Information, Security, Machine Learning / AI, Radio, Social Media, Software as a Service, Software Development, Strategy, Testing, Web Development.
              </h5>
              <h3 class="title">
                Tools
              </h3>
              <h5 class="description">
                .net, Android, AWS, Azure, C#, iOS, MySQL, node.js, Postgres, PowerShell, Python, R, REST, SQL Server, vue.js, Windows, Xero.
              </h5>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="section section-about-team section-team-1 pb-0">
        <div class="container">
          <div class="md-layout">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
            >
              <h2 class="title">
                Our Team
              </h2>
              <h5 class="description">
                Our team is diverse and highly skilled and we work together to meet the challenges of our clients.
              </h5>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam1"
              >
                <template slot="cardContent">
                  <h4 class="card-title">
                    Richard Fernando
                  </h4>
                  <h6 class="card-category text-muted">
                    CEO
                  </h6>
                  <p class="card-description">
                    Boss and solver of all problems
                  </p>
                </template>
              </profile-card>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam2"
              >
                <template slot="cardContent">
                  <h4 class="card-title">
                    Willem Greenwood
                  </h4>
                  <h6 class="card-category text-muted">
                    Developer
                  </h6>
                  <p class="card-description">
                    Will figure out and build the best solution
                  </p>
                </template>
              </profile-card>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam3"
              >
                <template slot="cardContent">
                  <h4 class="card-title">
                    Sayani Ghosh
                  </h4>
                  <h6 class="card-category text-muted">
                    POC Health Manager
                  </h6>
                  <p class="card-description">
                    Co-ordinator of our healthcare software
                  </p>
                </template>
              </profile-card>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam4"
              >
                <template slot="cardContent">
                  <h4 class="card-title">
                    Glen Ward
                  </h4>
                  <h6 class="card-category text-muted">
                    Device Services Manager
                  </h6>
                  <p class="card-description">
                    Makes all our, and our clients stuff work
                  </p>
                </template>
              </profile-card>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam5"
              >
                <template slot="cardContent">
                  <h4 class="card-title">
                    Lauren Russel
                  </h4>
                  <h6 class="card-category text-muted">
                    I.T. Technician
                  </h6>
                  <p class="card-description">
                    Quality control officer
                  </p>
                </template>
              </profile-card>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam6"
              >
                <template slot="cardContent">
                  <h4 class="card-title">
                    Tipuna Rakatairi
                  </h4>
                  <h6 class="card-category text-muted">
                    Client Manager
                  </h6>
                  <p class="card-description">
                    Guides our customers and developers alike
                  </p>
                </template>
              </profile-card>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam7"
              >
                <template slot="cardContent">
                  <h4 class="card-title">
                    Millissa Bethal
                  </h4>
                  <h6 class="card-category text-muted">
                    I.T. Technician
                  </h6>
                  <p class="card-description">
                    Will help out at all times
                  </p>
                </template>
              </profile-card>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam8"
              >
                <template slot="cardContent">
                  <h4 class="card-title">
                    Sam Russell
                  </h4>
                  <h6 class="card-category text-muted">
                    Marketing and IT Technician
                  </h6>
                  <p class="card-description">
                    Has ideas and energy projects
                  </p>
                </template>
              </profile-card>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-33 md-small-size-100">
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam10"
              >
                <template slot="cardContent">
                  <h4 class="card-title">
                    Natasha Carr
                  </h4>
                  <h6 class="card-category text-muted">
                    IT Technician
                  </h6>
                  <p class="card-description">
                    Will sort out problems
                  </p>
                </template>
              </profile-card>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam10"
              >
                <template slot="cardContent">
                  <h4 class="card-title">
                    Melissa Boyd
                  </h4>
                  <h6 class="card-category text-muted">
                    Designer / UI Developer
                  </h6>
                  <p class="card-description">
                    Spends time making it all look pretty
                  </p>
                </template>
              </profile-card>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam11"
              >
                <template slot="cardContent">
                  <h4 class="card-title">
                    Miela Lacanilao
                  </h4>
                  <h6 class="card-category text-muted">
                    Developer
                  </h6>
                  <p class="card-description">
                    Builds the good stuff
                  </p>
                </template>
              </profile-card>
            </div>
          </div>
        </div>
      </div>-->
      <div class="section section-about-office">
        <div class="container">
          <div class="md-layout text-center">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
              <h3 class="title">
                Interested in any of our services? Need help?
              </h3>
            </div>
            <div class="md-layout-item md-size-100 mx-auto text-center">
          <lets-talk />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/material-kit-mixins";
import LetsTalk from "./components/LetsTalk";
import FcValues from "./components/FcValues";

export default {
  components: {
    LetsTalk,
    FcValues
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      speciality: "",
      name: null,
      email: null,
      image: require("@/assets/img/FC_463548093.jpg"),
      aboutTeam: {
        aboutTeam1: require("@/assets/img/faces/Richard.jpg"),
        aboutTeam2: require("@/assets/img/faces/Willem.jpg"),
        aboutTeam3: require("@/assets/img/faces/Sayani.jpg"),
        aboutTeam4: require("@/assets/img/faces/Glen.jpg"),

        aboutTeam5: require("@/assets/img/faces/Lauren.jpg"),
        aboutTeam6: require("@/assets/img/faces/Tipuna.jpg"),
        aboutTeam7: require("@/assets/img/faces/Millissa.jpg"),
        aboutTeam8: require("@/assets/img/faces/Sam.jpg"),
        
        aboutTeam9: require("@/assets/img/placeholder.jpg"),
        aboutTeam10: require("@/assets/img/placeholder.jpg"),
        aboutTeam11: require("@/assets/img/placeholder.jpg"),
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.mt-2 {
  margin-top: 1.5rem;
}
</style>
