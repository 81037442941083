<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter" parallax-active="true"
      :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-50 md-small-size-100">
            <h1 class="title">Supporting your business with Smart Technology</h1>
            <h4>Firecrest is your business support team. We provide you creative and technical solutions so you can work better every day.</h4>
            <br>
            <md-button class="md-primary md-lg" @click="scrollToElement('services')">
                  Let's talk about I.T.
              </md-button>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100 pc-only">
            <br/>
            <img src="@/assets/img/FC_Working.png"/>
          </div>
        </div>
      </div>
    </parallax>

    <div id="main-panel" class="main main-raised">

<!-- Services Section --><div id="cd-vertical-nav"></div>
      <div class="section section-sections" id="services">
        <services-block />
        <br>
        <div class="md-layout-item md-size-100 md-small-size-100 mx-auto text-center">
            <h3 class="title">Interested in any of our services?</h3>
          <lets-talk />
        </div>
      </div>
      
<!-- About Section -->
      <div class="section section-dark" id="about">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 mx-auto text-center">
              <h2 class="title text-center">
                Who we are
              </h2>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <img src="@/assets/img/FC_ideasgroup1.png"/>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <h4 class="description">Since 2007, we have been partnering with organisations big and small around the world. Our goal is to continue providing quality services and solutions to organisations in need to further grow our community​</h4>
              <br>
              <md-button class="md-primary md-lg" href="#/about-us">
              Learn more about us
              </md-button>
            </div>
          </div>
        </div>
      </div>

<!-- Case Studies Section -->
      <div class="section section-features-5 section-image" id="case-studies"
      :style="features5">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 mx-auto text-center">
    <h2 class="title text-center">Customer Solutions</h2>
            <h4 class="text-center description">We are committed to assisting our customers</h4>
            <h5 class="text-center description">We guide our clients through the ‘forest’ of decisions that is I.T. We even name our services after trees.</h5>
            </div>
        </div>
          <case-studies />
        </div>
      </div>

<!-- Fight Section -->
      <div class="section section-sections" id="flight">
    <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 mx-auto text-center">
              <h2 class="title text-center">
                Take our flight to success
              </h2>
            </div>
        </div>
          <flight />
          <div class="md-layout">
            <div class="md-layout-item md-size-100 mx-auto text-center">
          <lets-talk />
            </div>
        </div>
      </div>
      </div>

    </div>
  </div>
</template>

<script>
import ServicesBlock from "./components/ServicesBlock";
import LetsTalk from "./components/LetsTalk";
import CaseStudies from "./components/CaseStudies";
import Flight from "./components/Flight";
import Mixins from "@/plugins/material-kit-mixins";

export default {
  name: "Index",
  components: {
    ServicesBlock,
    LetsTalk,
    CaseStudies,
    Flight
  },
  mixins: [Mixins.VerticalNav, Mixins.HeaderImage],
  bodyClass: "index-page",
  data() {
    return {
      image: require("@/assets/img/FC-bk1.jpg"),
      bkimage: require("@/assets/img/FC_411400135.jpg"),
      letsTalkModal: false,
    };
  },
  computed: {
    features5() {
      return {
        backgroundImage: `url(${this.bkimage})`
      };
    }
  }
};
</script>
<style lang="scss" scoped>

.section-features-5:after {
  background: rgba(0, 0, 0, 0.55) !important;
}

@media (max-width: 991px){
.index-page .page-header {
  min-height: 600px;
}
.index-page h1 {
  font-size: 2.3125rem;
}
  }
</style>
