<template>
  <div>
    <md-button class="md-primary md-lg" @click="valuesModal = true">
      <md-icon>assignment</md-icon> Firecrest Company Values
    </md-button>
    <div class="modal modal-bg" :class="{ show: valuesModal }">
      <modal v-if="valuesModal" modal-with-card type="modal-values" card-classes="md-card-signup" @close="valuesModal = false">
        <template slot="header">
          <md-button class="md-simple md-close md-just-icon md-round modal-default-button" @click="valuesModal = false">
            <md-icon>clear</md-icon>
          </md-button>
          <h3 class="title text-center text-primary">
            Firecrest - Company Values
          </h3>
        </template>
        <template slot="body">
          <div class="md-layout">
            <div slot="content-left" class="md-layout-item md-size-40 md-medium-size-50 md-small-size-100 ml-auto left">
              <div class="info info-horizontal">
                <div class="description text-left">
                  <h4 class="info-title text-primary">Helping Our Clients Succeed</h4>
                  <p class="description">Our client's success is our success. Our duty is to advise our clients and make the best recommendations we can for their interests alone. </p>

                  <h4 class="info-title text-primary">Delivering on Time and On Budget</h4>
                  <p class="description">When we commit to delivering, we always deliver.</p>

                  <h4 class="info-title text-primary">Challenge and Critique with Maturity and Humility</h4>
                  <p class="description">We want to accomplish the best we can, which means examining how we work now. The whole team is encouraged to critique and question our processes and ideas. This is done with fact, rationale, and an alternative solution - and is not an opportunity for dismissive attitudes or egos.</p>
                </div>
              </div>
            </div>
            <div slot="content-right" class="md-layout-item md-size-40 md-medium-size-50 md-small-size-100 mr-auto">
              <div class="info info-horizontal">
                <div class="description text-left">
                  <h4 class="info-title text-primary">Support the Team </h4>
                  <p class="description">Everyone is encouraged to ask for help from the entire team. As a collective we are stronger.</p>
                  
                  <h4 class="info-title text-primary">Learn, Improve, Grow</h4>
                  <p class="description">Everyone is encouraged and supported to keep learning and stay current.</p>
                  
                  <h4 class="info-title text-primary">Leave No Footprints</h4>
                  <p class="description">We leave no footprints in the data, or environmentally.</p>
                  
                  <h4 class="info-title text-primary">Cause No Harm, Plant More Trees</h4>
                  <p class="description">We are selective about the projects and people we work with - encouraging projects, technologies, and processes that are beneficial to both our communities and our environment.</p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { Modal } from "@/components/material-kit";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      valuesModal: false,
    }}
};
</script>

<style lang="scss" scoped>
.modal-values {
  max-width: 900px !important;
}
.left{
  border-right: solid 1px #ccc !important;
}
@media all and (max-width: 950px) {
.left {
    border-right: none !important;
  }
}
</style>
